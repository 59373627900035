import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import flatten from "@turf/flatten";
import { along, center, length } from "@turf/turf";
import { Feature, FeatureCollection, Geometry, Point } from "geojson";
import { CircleLayerSpecification, FillLayerSpecification, GeoJSONSource, LayoutSpecification, LineLayerSpecification, LngLatLike, PaintSpecification, SymbolLayerSpecification } from "mapbox-gl";
import { CoreoAttribute, CoreoCollectionItem, CoreoDataStyle, CoreoForm } from "../../types";
import AppDatabase from "../db/app-db.service";
import { projectFilePath } from "../db/filesystem.service";
import { MapLayer } from "./maps-base-layer";
import { createDefaultDataLayerStyle, formToDataStyle, linePaint, pointPaint, polygonBorderPaint, polygonFillPaint, safelyAddLayer, safelyAddSource, safelyRemoveFeatureState, safelyRemoveSource, safelySetZoomRange } from "./maps.utils";

const defaultDataLayerStyle = createDefaultDataLayerStyle();
const defaultPointPaint = pointPaint(defaultDataLayerStyle);
const defaultLinePaint = linePaint(defaultDataLayerStyle);
const defaultPolygonFillPaint = polygonFillPaint(defaultDataLayerStyle);
const defaultPolygonBorderPaint = polygonBorderPaint(defaultDataLayerStyle);

const getMapIconUrl = async (projectId: number, itemId: number) => {
  const path = projectFilePath(projectId, 'items', itemId);
  const result = await Filesystem.getUri({
    directory: Directory.Data,
    path
  });
  return Capacitor.convertFileSrc(result.uri);
}

export class MapDataLayer extends MapLayer {
  private styles: (CoreoDataStyle & { id: number; sort: number })[] = [];
  private visible: boolean = true;
  private styleKey: string = 'surveyId';

  private clusterMaxZoom: number = 12;
  private clusterRadius: number = 50;
  private source: mapboxgl.GeoJSONSource;
  private selectedFeatureId: number = null;

  private styleSourceId = `${this.id}-style`;
  private styleSource: mapboxgl.GeoJSONSource;

  private features: Feature<Geometry>[] = [];
  private styleFeatures: Feature<Point>[] = [];

  constructor(private cluster: boolean = false) {
    super();
  }

  add(id: number, sort: number, style: CoreoDataStyle) {
    const idx = this.styles.findIndex(s => s.id === id);
    if (idx === -1) {
      this.styles.push({
        ...defaultDataLayerStyle,
        ...style,
        sort,
        id
      });
    } else {
      this.styles[idx] = {
        ...this.styles[idx],
        sort,
        ...style
      };
    }
  }

  addForm(form: CoreoForm) {
    const style = formToDataStyle(form);
    this.add(form.id, form.mapSort, style);
  }

  move(): void {
    super.move();
    if (this.cluster) {
      this.map.moveLayer(`${this.id}-symbols`, `${this.id}-clusters`);
    }
  }

  setSort(id: number, sort: number) {
    const idx = this.styles.findIndex(s => s.id === id);
    if (idx === -1) {
      return;
    }
    this.styles[idx] = {
      ...this.styles[idx],
      sort
    };
  }

  clear() {
    this.styles = [];
  }

  clusterClickHandler = (ev) => {
    const [cluster] = ev.features;
    this.source.getClusterExpansionZoom(cluster.properties.cluster_id, (err, zoom) => {
      if (err) {
        console.warn(err);
      } else {
        this.map.easeTo({
          center: cluster.geometry.coordinates as LngLatLike,
          zoom
        });
      }
    });
  };

  private setupMapEvents() {
    this.map.off('click', `${this.id}-clusters`, this.clusterClickHandler);
    this.map.on('click', `${this.id}-clusters`, this.clusterClickHandler);
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    safelyAddSource(map, this.id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.features
      },
      promoteId: 'id',
      cluster: this.cluster,
      clusterMaxZoom: this.clusterMaxZoom,
      clusterRadius: this.clusterRadius,
      buffer: this.cluster ? 0 : 128,
      maxzoom: 24
    });

    this.source = map.getSource(this.id) as GeoJSONSource;

    safelyAddSource(map, this.styleSourceId, {
      type: 'geojson',
      promoteId: 'id',
      data: {
        type: 'FeatureCollection',
        features: this.styleFeatures
      },
      cluster: this.cluster,
      clusterMaxZoom: this.clusterMaxZoom,
      clusterRadius: this.clusterRadius,
      buffer: this.cluster ? 0 : 128,
      maxzoom: 24
    });

    this.styleSource = map.getSource(this.styleSourceId) as GeoJSONSource;

    this.map = map;

    await this.createClusterLayer();
    await this.createPolygonLayer();
    await this.createLineLayer();
    await this.createPointLayer();
    await this.createSymbolsLayer();

    this.setupMapEvents();
  }

  show(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'visible');
      }
    }
    this.visible = true;
  }

  hide(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'none');
      }
    }
    this.visible = false;
  }

  setFeatures(features: Feature<Geometry>[]) {
    this.features = features;
    const fc: FeatureCollection = {
      type: 'FeatureCollection',
      features
    };
    this.source?.setData(fc);

    // Flatten out the symbols
    const flat = flatten(fc as any) as FeatureCollection<Geometry>;
    const styleFeatures: Feature<Point>[] = [];

    for (const f of flat.features) {
      switch (f.geometry.type) {
        case 'LineString': {
          const lineLength = length(f, { units: 'meters' });
          const lineAlong = along(f.geometry, lineLength / 2, { units: 'meters' });
          f.geometry = lineAlong.geometry;
          styleFeatures.push({
            ...f,
            geometry: lineAlong.geometry
          });
          break;
        }
        case 'Polygon': {
          f.geometry = center(f.geometry).geometry;
          styleFeatures.push({
            ...f,
            geometry: center(f.geometry).geometry
          });
          break;
        }
        case 'Point': {
          styleFeatures.push(f as Feature<Point>);
          break;
        }
      }
    }
    this.styleFeatures = styleFeatures
    this.styleSource?.setData({
      type: 'FeatureCollection',
      features: styleFeatures
    });
  }

  selectFeature(id: number) {
    if (this.selectedFeatureId) {
      safelyRemoveFeatureState(this.map, {
        source: this.id,
        id: this.selectedFeatureId
      }, 'selected');
    }
    this.selectedFeatureId = id;
    this.map.setFeatureState({
      source: this.id,
      id
    }, {
      selected: true
    });
  }

  deselectFeature() {
    if (this.selectedFeatureId) {
      safelyRemoveFeatureState(this.map, {
        source: this.id,
        id: this.selectedFeatureId
      }, 'selected');
    }
    this.selectedFeatureId = null;
  }

  setZoomRange(minZoom: number, maxZoom: number) {
    for (const l of this.layerIds()) {
      safelySetZoomRange(this.map, l, minZoom, maxZoom);
    }
  }

  layerIds(): string[] {
    return [
      `${this.id}-polygon`,
      `${this.id}-polygon-border`,
      `${this.id}-linestring`,
      `${this.id}-point`,
      `${this.id}-clusters`,
      `${this.id}-clusters-counts`,
      `${this.id}-symbols`
    ];
  }

  private createSortKeyExpression(): mapboxgl.ExpressionSpecification {
    const sort: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
    for (const style of this.styles) {
      sort.push(~~style.id);
      sort.push(style.sort);
    }
    sort.push(0);
    return sort;
  }

  private createLayerLayout(sortKey: string): LayoutSpecification {
    if (this.styles.length <= 1) {
      return {
        'visibility': this.visible ? 'visible' : 'none'

      };
    }
    return {
      'visibility': this.visible ? 'visible' : 'none',
      [sortKey]: this.createSortKeyExpression()
    };
  }

  private async createPaint<T extends PaintSpecification>(fn: (s: CoreoDataStyle) => Promise<T>, def: Promise<T>): Promise<T> {
    if (this.styles.length === 0) {
      return def;
    }

    if (this.styles.length === 1) {
      return fn(this.styles[0]);
    }

    const [first, ...styles] = this.styles;
    const result: any = {};

    // Build the initial state
    const firstPaint = await fn(first);
    for (const p in firstPaint) {
      result[p] = ['match', ['get', this.styleKey], first.id, firstPaint[p]];
    }
    for (const style of styles) {
      const paint = await fn(style);
      for (const p in paint) {
        result[p].push(style.id);
        result[p].push(paint[p]);
      }
    }

    const defaultStyle = await def;
    for (const p in defaultStyle) {
      result[p].push(defaultStyle[p]);
    }

    return result as T;
  }

  private async createPointPaint(): Promise<CircleLayerSpecification['paint']> {
    return this.createPaint<mapboxgl.CircleLayerSpecification['paint']>(pointPaint, defaultPointPaint);
  }

  private async createLinePaint(): Promise<LineLayerSpecification['paint']> {
    return this.createPaint<LineLayerSpecification['paint']>(linePaint, defaultLinePaint);
  }

  private async createPolygonFillPaint(): Promise<FillLayerSpecification['paint']> {
    return this.createPaint<FillLayerSpecification['paint']>(polygonFillPaint, defaultPolygonFillPaint);
  }

  private async createPolygonBorderPaint(): Promise<LineLayerSpecification['paint']> {
    return this.createPaint<LineLayerSpecification['paint']>(polygonBorderPaint, defaultPolygonBorderPaint);
  }

  private async createPointLayer() {
    const filter = await this.createPointFilter();
    safelyAddLayer(this.map, {
      id: `${this.id}-point`,
      source: this.id,
      type: 'circle',
      filter,
      layout: this.createLayerLayout('circle-sort-key'),
      paint: await this.createPointPaint()
    });
  }

  private async createLineLayer() {

    safelyAddLayer(this.map, {
      id: `${this.id}-linestring`,
      source: this.id,
      type: 'line',
      filter: ["all",
        ["==", ["geometry-type"], "LineString"],
        ["!", ["has", "point_count"]]
      ],
      paint: await this.createLinePaint(),
      layout: this.createLayerLayout('line-sort-key'),
    });
  }

  private createClusterCirclePaint(): CircleLayerSpecification['paint'] {
    return {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['get', 'point_count'],
        0, 16,
        this.features?.length || 1,
        40
      ],
      'circle-stroke-width': 3,
      'circle-color': '#fff',
      'circle-stroke-color': this.styles.length === 1 ? this.styles[0].color : '#0069DF',
    };
  }

  private createClusterCountLayout(): SymbolLayerSpecification['layout'] {
    return {
      'text-field': '{point_count_abbreviated}',
      'text-size': [
        'interpolate',
        ['linear'],
        ['get', 'point_count'],
        0, 14,
        this.features?.length || 1,
        22
      ]
    };
  }

  private createClusterLayer() {

    safelyAddLayer(this.map, {
      id: `${this.id}-clusters`,
      type: 'circle',
      source: `${this.id}`,
      filter: ['has', 'point_count'],
      paint: this.createClusterCirclePaint()
    });

    safelyAddLayer(this.map, {
      id: `${this.id}-clusters-counts`,
      type: 'symbol',
      source: this.id,
      filter: ['has', 'point_count'],
      layout: this.createClusterCountLayout(),
      paint: {
        'text-color': '#000'
      }
    });
  }

  private async createPolygonLayer() {

    safelyAddLayer(this.map, {
      id: `${this.id}-polygon`,
      source: this.id,
      type: 'fill',
      filter: ["all",
        ["==", ["geometry-type"], "Polygon"],
        ["!", ["has", "point_count"]]
      ],
      paint: await this.createPolygonFillPaint(),
      layout: this.createLayerLayout('fill-sort-key')
    });

    // // Polygon Border
    safelyAddLayer(this.map, {
      id: `${this.id}-polygon-border`,
      source: this.id,
      type: 'line',
      filter: ["all",
        ["==", ["geometry-type"], "Polygon"],
        ["!", ["has", "point_count"]]
      ],
      paint: await this.createPolygonBorderPaint(),
      layout: this.createLayerLayout('line-sort-key'),
    });
  }

  private async getCollectionItemsWithIconForAttribute(attributeId: number): Promise<[CoreoAttribute, CoreoCollectionItem[]]> {
    const attribute = await AppDatabase.instance.attributes.findById(attributeId);
    if (!attribute || attribute.type !== 'select') {
      return [attribute, []];
    }
    const items = await AppDatabase.instance.items.search(q =>
      q.where('collectionId = ?', attribute.collectionId).where('icon IS NOT NULL')
    ).toArray();
    return [attribute, items];
  }

  private async createSymbolsLayout(): Promise<SymbolLayerSpecification['layout']> {

    const iconSourceSize = 256;
    const targetSize = 32;
    const iconSize = 1 / (iconSourceSize / targetSize);

    const icons: SymbolLayerSpecification['layout'] = {
      'icon-optional': false,
      'icon-anchor': 'bottom',
      'icon-image': '{__icon}',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-size': iconSize
    };

    if (this.styles.length === 0) {
      return icons;
    }

    return {
      ...icons,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
      'text-size': 16,
      'text-optional': false,
      'text-anchor': 'top',
      'text-field': '{__label}',
      'text-font': ['Open Sans Regular'],
      'symbol-placement': 'point'
    };
  }

  private async createSymbolsLayer() {
    const layout = await this.createSymbolsLayout();
    safelyAddLayer(this.map, {
      id: `${this.id}-symbols`,
      source: this.styleSourceId,
      type: 'symbol',
      layout,
      paint: {
        'text-color': '#000',
        "text-halo-color": "#fff",
        "text-halo-width": 2
      }
    });
  }

  private async createPointFilter(): Promise<mapboxgl.ExpressionSpecification> {
    return ["all",
      ["==", ["geometry-type"], "Point"],
      ["!", ["has", "point_count"]],
      ["!", ["has", "__icon"]]
    ];
  }

  updatePaint(layerId: string, paint: PaintSpecification) {
    for (const [k, v] of Object.entries(paint)) {
      this.map.setPaintProperty(layerId, k as keyof PaintSpecification, v);
    }
  }

  updateLayout(layerId: string, layout: LayoutSpecification) {
    for (const [k, v] of Object.entries(layout)) {
      this.map.setLayoutProperty(layerId, k as keyof LayoutSpecification, v);
    }
  }

  async update() {
    if (!this.map) {
      return;
    }

    const [pointPaint, linePaint, polygonFillPaint, polygonBorderPaint, clusterCirclePaint, symbolsLayout] = await Promise.all([
      this.createPointPaint(),
      this.createLinePaint(),
      this.createPolygonFillPaint(),
      this.createPolygonBorderPaint(),
      this.createClusterCirclePaint(),
      this.createSymbolsLayout(),
      this.createPointFilter()
    ]);

    this.updatePaint(`${this.id}-point`, pointPaint);
    this.updatePaint(`${this.id}-linestring`, linePaint);
    this.updatePaint(`${this.id}-polygon`, polygonFillPaint);
    this.updatePaint(`${this.id}-polygon-border`, polygonBorderPaint);
    this.updatePaint(`${this.id}-clusters`, clusterCirclePaint);

    this.updateLayout(`${this.id}-point`, this.createLayerLayout('circle-sort-key'));
    this.updateLayout(`${this.id}-linestring`, this.createLayerLayout('line-sort-key'));
    this.updateLayout(`${this.id}-polygon`, this.createLayerLayout('fill-sort-key'));
    this.updateLayout(`${this.id}-polygon-border`, this.createLayerLayout('line-sort-key'));
    this.updateLayout(`${this.id}-clusters-counts`, this.createClusterCountLayout());
    this.updateLayout(`${this.id}-symbols`, symbolsLayout);
  }

  remove(): void {
    // Remove event bindings
    this.map?.off('click', `${this.id}-clusters`, this.clusterClickHandler);
    this.source = undefined;

    super.remove();
    safelyRemoveSource(this.map, this.styleSourceId);
    this.styleSource = undefined;
  }
}
